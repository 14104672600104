export default ($axios) => ({
  find(siglaEmpresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/ged/${siglaEmpresa}/GAC/Plano Obras${parms}`)
  },

  getPlanoObraById(siglaEmpresa, idAnexo) {
    return $axios.get(`/ged/${siglaEmpresa}/${idAnexo}`)
  },

  savePlanoObra(siglaEmpresa, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(`/ged/${siglaEmpresa}/GAC/Plano Obras`, formData)
  },

  downloadPlanoObra(siglaEmpresa, uuid) {
    return $axios.get(`/downloads/${siglaEmpresa}/anexos/GAC/${uuid}`, {
      responseType: 'blob',
    })
  },

  deletePlanoObra(siglaEmpresa, idAnexo) {
    return $axios.delete(`/ged/remover/${siglaEmpresa}/${idAnexo}`)
  },

  updatePlanoObra(siglaEmpresa, uuid, token, formData) {
    $axios.setToken(token, 'Bearer', ['put'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.put(`/ged/${siglaEmpresa}/${uuid}`, formData)
  },
})
